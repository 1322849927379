import Convenience from '../../Assets/Images/convenience.svg';
import Support from '../../Assets/Images/support.svg';
import Online from '../../Assets/Images/online.svg';
import Competetion from '../../Assets/Images/competetion.svg';

export const FEATURES = [
   
    {
        icon:Support,
        heading:'24/7 Seller Support: Your Success is Our Priority',
        description:'NearShopz offers dedicated 24/7 seller support. Our team is readily available to answer your questions, troubleshoot issues, and ensure your online store runs smoothly.'
    },
    {
        icon:Online,
        heading:'Effortless Online Presence: Reach New Customers Instantly',
        description:"Forget the hassle of building and maintaining your own website. NearShopz provides a user-friendly online storefront, allowing you to showcase your products and reach a wider audience instantly."
    },
    {
        icon:Convenience,
        heading:'Competitive Edge: Stand Out in the Online Marketplace ',
        description:"Don't get lost in the crowd! NearShopz empowers local businesses to compete effectively with established online retailers. We provide the tools and support you need to optimize your listings and stand out from the competition."
    },
    {
        icon:Competetion,
        heading:'Increased Sales & Convenience: Grow Your Business with NearShopz ',
        description:'NearShopz connects you with a vast network of potential customers actively seeking local delivery options. Leverage our platform to expand your reach, boost sales, and enjoy the convenience of a streamlined online sales channel.'
    },
   

];