import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { db } from '../db';
import { useLiveQuery } from 'dexie-react-hooks';
import { useStyles } from "./style.js";

//add to cart bug button 
//berlin
const ExpandButton = (props) => {
    const classes = useStyles();
    const [productCount, setProductCount] = useState(0);
    const [cartData, setCartData] = useState([]);
    const { cart } = db;
    const cartdata = useLiveQuery(() => cart.toArray(), []);

    console.log("im berlin storeid",props.storeid)

    const handleAdd = async (productID, productName, productImage, price, promoprice, storeID, storeName, uom, productquantity, q) => {

        console.log("the handleAdd issue",storeID)
        let updatedCartData;
        const storedata = await db.cart.get(storeID);
        if (!storedata) {
            if (cartdata.length >= 5) {
                props.props.setCartlimit();
            } else {
                updatedCartData = {
                    storeid: storeID,
                    storename: storeName,
                    totalPrice: 5000,
                    products: [{
                        productid: productID,
                        productQuantity: productquantity,
                        cartquantity: 1,
                        productname: productName,
                        image: productImage,
                        productprice: promoprice !== null ? Number(promoprice) : Number(price),
                        uom: uom
                    }]
                };
                setProductCount(1);
            }
        } else {
            const existingProduct = storedata.products.find(product => product.productid === productID);
            if (existingProduct) {
                const updatedProducts = storedata.products.map(product =>
                    product.productid === productID
                        ? { ...product, cartquantity: product.cartquantity + q }
                        : product
                );
                updatedCartData = { ...storedata, products: updatedProducts };
                setProductCount(productCount + q);
            } else {
                updatedCartData = {
                    ...storedata,
                    products: [
                        ...storedata.products,
                        {
                            productid: productID,
                            productQuantity: productquantity,
                            cartquantity: 1,
                            productname: productName,
                            image: productImage,
                            productprice: promoprice !== null ? Number(promoprice) : Number(price),
                            uom: uom
                        }
                    ]
                };
            }
        }

        if (!storedata) {
            await db.cart.add(updatedCartData);
        } else {
            await db.cart.update(storeID, updatedCartData);
        }
        setCartData(cartdata);
    };

    const handleRemove = async (storeID, productID) => {
        const storedata = await db.cart.get(storeID);
        if (storedata) {
            const updatedProducts = storedata.products.filter(product => product.productid !== productID);
            const updatedCartData = { ...storedata, products: updatedProducts };
            if (updatedProducts.length === 0) {
                await db.cart.delete(storeID);
                setProductCount(0);
            } else {
                await db.cart.update(storeID, updatedCartData);
                setProductCount(0);
            }
            setCartData(cartdata);
        }
    };

    const checkCount = async (storeid, id) => {
        const storedata = await db.cart.get(storeid);
        if (!storedata || !storedata.products) {
            setProductCount(0);
        } else {
            const product = storedata.products.find(product => product.productid === id);
            if (!product) {
                setProductCount(0);
            } else {
                setProductCount(product.cartquantity);
            }
        }
    };

    useEffect(() => {
        checkCount(props.storeid, localStorage.getItem('productid'));
    }, [cartdata, props.storeid]);

    return (
        <React.Fragment>
            {productCount === 0 ? (
                props?.props?.StoreDetails?.storeWorking === true &&<div className={classes.addtocartbutton}
                    onClick={() => {
                        handleAdd(localStorage.getItem('productid'), props.productname, props.image, props.price, props.promoprice, props.storeid,
                            props.storename, props.uom, props.quantity, 1);
                    }}>
                    {'Add to cart'}
                </div>
            ) : (
                <ButtonGroup variant="text"
                    style={{
                        width: '100%',
                        height: '38px',
                        userSelect: 'none',
                        borderRadius: '10px', backgroundColor: '#ffffff', fontSize: '12px',
                        border: '1px solid rgba(105, 168, 92, 0.1)',
                    }}
                    aria-label="outlined button group"
                >
                    {productCount === 1 ? (
                        <Button
                            style={{
                                background: 'rgba(209, 216, 224, 0.34)',
                                border: 'none',
                                width: '38px',
                                height: '38px',
                                borderRadius: '10px',
                                color: '#85BE49',
                                fontSize: '9px',
                            }}
                            onClick={() => {
                                setProductCount(0);
                                handleRemove(props.storeid, localStorage.getItem('productid'));
                            }}
                        >
                            <DeleteOutlineIcon />
                        </Button>) : (
                        productCount === 0 || productCount < 1 ? (
                            <Button
                                style={{
                                    background: 'rgba(209, 216, 224, 0.34)',
                                    border: 'none',
                                    width: '38px',
                                    height: '38px',
                                    borderRadius: '10px',
                                    color: '#000000',
                                    userSelect: 'none',
                                }}
                                disabled>
                                -
                            </Button>
                        ) : (<Button
                            style={{
                                background: 'rgba(209, 216, 224, 0.34)',
                                border: 'none',
                                width: '38px',
                                height: '38px',
                                borderRadius: '10px',
                                color: '#000000',
                                userSelect: 'none',
                            }}
                            onClick={() => {
                                handleAdd(localStorage.getItem('productid'), props.productname, props.image, props.price, props.promoprice, props.storeid,
                                    props.storename, props.uom, props.quantity, -1);
                            }}
                        >
                            -
                        </Button>))}
                    <Button style={{
                        border: 'none', color: '#000000', fontSize: '12px', width: '100%',
                        userSelect: 'none',
                        background: '#ffffff',
                    }}>
                        {productCount}
                    </Button>
                    <Button
                        disabled={productCount > 49 ? true : false}
                        style={
                            productCount > 49 ?
                                {
                                    background: 'rgba(196, 196, 196)',
                                    boxShadow: '0px 9px 17px rgba(0, 0, 0, 0.1)',
                                    width: '38px',
                                    height: '38px',
                                    borderRadius: '10px',
                                    color: '#ffffff',
                                    userSelect: 'none',
                                } :
                                {
                                    background: 'linear-gradient(180deg, #85BE49 0%, #69A85C 100%)',
                                    boxShadow: '0px 9px 17px rgba(0, 0, 0, 0.1)',
                                    width: '38px',
                                    height: '38px',
                                    borderRadius: '10px',
                                    color: '#ffffff',
                                    userSelect: 'none',
                                }}
                        onClick={() => {
                            handleAdd(localStorage.getItem('productid'), props.productname, props.image, props.price, props.promoprice, props.storeid,
                                props.storename, props.uom, props.quantity, 1);
                        }}
                    >+</Button>
                </ButtonGroup>
            )}
        </React.Fragment>
    );
};
export default ExpandButton;
