import React, { useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useStyles } from "./style.js";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { db } from "../db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Stack from "@mui/material/Stack";
import AlertBar from "../AlertBar/index.js";
//import { useLiveQuery } from "dexie-react-hooks";

const ExpandButton = (props) => {


  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();
  const [state, setState] = React.useState({
    quantity: 0,
    productCount: 0,
    counter: false,
    cartData: [],
    itemfound: false,
  });
  const [open, setOpen] = React.useState(false);
  // const [open,setOpen] =React.useState(false);
  const { cart } = db;
  //const cartdata = useLiveQuery(() => cart.toArray(), []);
  //console.log("this is the state form the expandButton store", props.props.StoreDetails.storeWorking)

  console.log("im the cart", cart)
  const cartdata = useLiveQuery(() => cart.toArray(), [cart]);

  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);
  };
  // const removestorefromcart = () => {
  //   setOpenAlert(false);
  // };


  const removestorefromcart = async (storeid) => {

    console.log("im inside the removeStoreformcart", storeid)
    await db.cart.delete(storeid);
    setOpenAlert(false);
  };

  const handleStoreClose = () => {
    setOpenAlert(false)
  }

  // create cart
  const calCulateStoretotal = (products) => {
    let t = 0;
    products.map(
      (p) => (t = t + Number(p.cartquantity) * Number(p.productprice))
    );
    return t;
  };
  const handleAdd = async (
    productID,
    productName,
    productImage,
    price,
    promoprice,
    storeID,
    storeName,
    uom,
    productquantity,
    q
  ) => {
    let data,
      flag = false;
    let storedata = await db.cart.get(storeID);

    let Data = await db.cart.toArray();

    const itemsFromOtherStore = await db.cart
      .where("storeid")
      .notEqual(storeID)
      .count();

    if (itemsFromOtherStore > 0) {
      console.log("entered");

      setOpenAlert(true);
      console.log(openAlert);
      return;
    } else {
      console.log("entered in else");
      if (
        storedata === undefined ||
        storedata === "undefined" ||
        storedata === null ||
        storedata === "null"
      ) {
        if (cartdata.length >= 5) props.props.setCartlimit();
        else {
          setState({ productCount: Number(state.productCount + q) });
          data = {
            storeid: `${storeID}`,
            storename: `${storeName}`,
            totalPrice:
              promoprice !== null ? Number(promoprice) : Number(price),
            products: [
              {
                productid: `${productID}`,
                productQuantity: productquantity,
                promoprice: promoprice,
                cartquantity: 1,
                productname: `${productName}`,
                image: productImage,
                productprice:
                  promoprice !== null ? Number(promoprice) : Number(price),
                uom: uom,
              },
            ],
          };
          setState({ ...state, productCount: 1, cartData: cartdata });
        }
      } else {
        storedata.products.forEach((product, index) => {
          if (product.productid === productID) {
            flag = true;
            // eslint-disable-next-line
            let productindex = storedata.products.findIndex(
              (p) => p.productid === productID
            );
            let filteredProductList = storedata.products.filter(
              (p) => p.productid !== productID
            );
            let productList = storedata.products.filter(
              (p) => p.productid !== productID
            );
            data = {
              storeid: `${storeID}`,
              storename: `${storeName}`,
              totalPrice: calCulateStoretotal(productList),
              products: [
                ...filteredProductList.slice(0, index),
                {
                  productid: `${productID}`,
                  productQuantity: productquantity,
                  promoprice: promoprice,
                  cartquantity: Number(product.cartquantity) + q,
                  productname: `${productName}`,
                  image: productImage,
                  productprice:
                    promoprice !== null ? Number(promoprice) : Number(price),
                  uom: uom,
                },
                ...filteredProductList.slice(index),
              ],
            };
            setState({
              ...state,
              productCount: state.productCount + q,
              cartData: cartdata,
            });
          }
        });
        if (flag === false) {
          data = {
            storeid: `${storeID}`,
            storename: `${storeName}`,
            totalPrice: calCulateStoretotal(storedata.products),
            products: [
              ...storedata.products,
              {
                productid: `${productID}`,
                productQuantity: productquantity,
                cartquantity: 1,
                productname: `${productName}`,
                promoprice: promoprice,
                image: productImage,
                productprice:
                  promoprice !== null ? Number(promoprice) : Number(price),
                uom: uom,
              },
            ],
          };
        }
      }
      if (
        storedata === undefined ||
        storedata === "undefined" ||
        storedata === null ||
        storedata === "null"
      ) {
        await db.cart.add(data);
        setState({ ...state, counter: !state.counter, cartData: cartdata });
      } else {
        await db.cart.update(storeID, data);
        setState({ ...state, counter: !state.counter, cartData: cartdata });
      }
    }

    // }
    // else setOpen(true);
  };

  // remove product from cart

  const removeStore = async (storeID) => {
    await db.cart.delete(storeID);
    setOpen(false);
  };

  const handleRemove = async (storeID, productID) => {
    // let data;
    let filteredProductList;
    setState({ ...state, productCount: 0 });
    let storedata = await db.cart.get(storeID);
    storedata.products.forEach((product) => {
      if (product.productid === productID) {
        filteredProductList = storedata.products.filter(
          (p) => p.productid !== productID
        );
        setState({ ...state, productCount: 0 });
        setState({ ...state, counter: !state.counter, cartData: cartdata });
      }
    });
    if (filteredProductList.length === 0) await db.cart.delete(storeID);
    else {
      await db.cart
        .where("storeid")
        .equals(`${storeID}`)
        .modify({ products: filteredProductList });
      if (props.application === "cart") {
        await db.cart.update(db.cart);
        checkCount(storeID, productID);
      }
      setState({ ...state, productCount: 0, cartData: db.cart });
      setState({ ...state, counter: !state.counter, cartData: cartdata });
    }
    setState({ ...state, productCount: 0, cartData: db.cart });
  };

  // check the product count in cart

  const checkCount = async (storeid, id) => {
    return await db.cart.get(storeid).then((q) => {
      if (q === undefined || q === "undefined" || q === null || q === "null") {
        setState({ ...state, productCount: 0 });
      } else {
        if (q.products.filter((p) => p.productid === id).length === 0) {
          setState({ ...state, productCount: 0 });
        }
        q.products.forEach((product) => {
          if (product.productid === id) {
            setState({
              ...state,
              productCount: product.cartquantity,
              cartData: cartdata,
            });
          }
        });
        // if(state.itemfound===false)
        // setState({ ...state, productCount: 0 });
      }
    });
  };

  React.useEffect(
    () => {
      checkCount(props.storeid, props.id);
    },
    // eslint-disable-next-line
    [cartdata]
  );

  React.useEffect(
    () => {
      checkCount(props.storeid, props.id);
    },
    // eslint-disable-next-line
    [state.counter, state.productCount, state.cartData, cartdata]
  );
  if (!cartdata || cartdata === undefined) return null;

  // console.log("cart data",cartdata)
  return (
    <React.Fragment>
      {/* {state.productCount === 0 ? (
        <div
          className={classes.addtocartbutton}
          onClick={() => {
            handleAdd(
              props.id,
              props.productname,
              props.image,
              props.price,
              props.promoprice,
              props.storeid,
              props.storename,
              props.uom,
              props.quantity,
              1
            );
          }}
        >
          {"Add to cart"}
        </div>
      ) : (
        <ButtonGroup
          variant="text"
          style={{
            width: "100%",
            height: "34px",
            userSelect: "none",
            borderRadius: "14px",
            backgroundColor: "#EAF6E1",
            border: "none",
            fontSize: "12px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          aria-label="outlined button group"
        >
          {state.productCount === 1 ? (
            <Button
              style={{
                background: "#FFFFFF",
                border: "1px solid rgba(0, 0, 0, 0.03)",
                width: "10%",
                height: "34px",
                borderRadius: "14px",
                color: "#85BE49",
                fontSize: "9px",
              }}
              onClick={() => {
                setState({ ...state, productCount: 0 });
                handleRemove(props.storeid, props.id);
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          ) : state.productCount === 0 || state.productCount < 1 ? (
            <Button
              style={{
                background: " #FFFFFF",
                border: "1px solid rgba(0, 0, 0, 0.03)",
                width: "10%",
                height: "34px",
                borderRadius: "14px",
                color: "#000000",
                userSelect: "none",
              }}
              disabled
            >
              -
            </Button>
          ) : (
            <Button
              style={{
                background: " #FFFFFF",
                border: "1px solid rgba(0, 0, 0, 0.03)",
                width: "10%",
                height: "34px",
                borderRadius: "14px",
                color: "#000000",
                userSelect: "none",
              }}
              onClick={() => {
                handleAdd(
                  props.id,
                  props.productname,
                  props.image,
                  props.price,
                  props.promoprice,
                  props.storeid,
                  props.storename,
                  props.uom,
                  props.quantity,
                  -1
                );
              }}
            >
              -
            </Button>
          )}
          <Button
            style={{
              border: "none",
              color: "#000000",
              fontSize: "12px",
              width: "80%",
              userSelect: "none",
            }}
          >
            {state.productCount}
          </Button>
          <Button
            disabled={state.productCount > 49 ? true : false}
            style={
              state.productCount > 49
                ? {
                  background: "rgba(196, 196, 196)",
                  border: "1px solid rgba(196, 196, 196, 0.2)",
                  width: "10%",
                  height: "34px",
                  borderRadius: "14px",
                  color: "#ffffff",
                  userSelect: "none",
                }
                : {
                  background: "#85BE49",
                  border: "1px solid rgba(0, 0, 0, 0.03)",
                  width: "10%",
                  height: "34px",
                  borderRadius: "14px",
                  color: "#ffffff",
                  userSelect: "none",
                }
            }
            onClick={() => {
              handleAdd(
                props.id,
                props.productname,
                props.image,
                props.price,
                props.promoprice,
                props.storeid,
                props.storename,
                props.uom,
                props.quantity,
                1
              );
            }}
          >
            +
          </Button>
        </ButtonGroup>
      )} */}

{props?.props?.StoreDetails?.storeWorking === true && (
  state.productCount === 0 ? (
    <div
      className={classes.addtocartbutton}
      onClick={() => {
        handleAdd(
          props.id,
          props.productname,
          props.image,
          props.price,
          props.promoprice,
          props.storeid,
          props.storename,
          props.uom,
          props.quantity,
          1
        );
      }}
    >
      {"Add to cart"}
    </div>
  ) : (
    <ButtonGroup
      variant="text"
      style={{
        width: "100%",
        height: "34px",
        userSelect: "none",
        borderRadius: "14px",
        backgroundColor: "#EAF6E1",
        border: "none",
        fontSize: "12px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
      aria-label="outlined button group"
    >
      {state.productCount === 1 ? (
        <Button
          style={{
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.03)",
            width: "10%",
            height: "34px",
            borderRadius: "14px",
            color: "#85BE49",
            fontSize: "9px",
          }}
          onClick={() => {
            setState({ ...state, productCount: 0 });
            handleRemove(props.storeid, props.id);
          }}
        >
          <DeleteOutlineIcon />
        </Button>
      ) : state.productCount === 0 || state.productCount < 1 ? (
        <Button
          style={{
            background: " #FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.03)",
            width: "10%",
            height: "34px",
            borderRadius: "14px",
            color: "#000000",
            userSelect: "none",
          }}
          disabled
        >
          -
        </Button>
      ) : (
        <Button
          style={{
            background: " #FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.03)",
            width: "10%",
            height: "34px",
            borderRadius: "14px",
            color: "#000000",
            userSelect: "none",
          }}
          onClick={() => {
            handleAdd(
              props.id,
              props.productname,
              props.image,
              props.price,
              props.promoprice,
              props.storeid,
              props.storename,
              props.uom,
              props.quantity,
              -1
            );
          }}
        >
          -
        </Button>
      )}
      <Button
        style={{
          border: "none",
          color: "#000000",
          fontSize: "12px",
          width: "80%",
          userSelect: "none",
        }}
      >
        {state.productCount}
      </Button>
      <Button
        disabled={state.productCount > 49 ? true : false}
        style={
          state.productCount > 49
            ? {
                background: "rgba(196, 196, 196)",
                border: "1px solid rgba(196, 196, 196, 0.2)",
                width: "10%",
                height: "34px",
                borderRadius: "14px",
                color: "#ffffff",
                userSelect: "none",
              }
            : {
                background: "#85BE49",
                border: "1px solid rgba(0, 0, 0, 0.03)",
                width: "10%",
                height: "34px",
                borderRadius: "14px",
                color: "#ffffff",
                userSelect: "none",
              }
        }
        onClick={() => {
          handleAdd(
            props.id,
            props.productname,
            props.image,
            props.price,
            props.promoprice,
            props.storeid,
            props.storename,
            props.uom,
            props.quantity,
            1
          );
        }}
      >
        +
      </Button>
    </ButtonGroup>
  )
)}


      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: "18px", padding: "30px" } }}
      >
        <DialogTitle
          style={{ color: "#000", fontWeight: 700, fontSize: "20px" }}
        >
          Replace Cart item?
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "left",
              alignItems: "center",
              paddingTop: "15px",
              color: "#7F7F7F",
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            {`Your cart contains items from ${cartdata &&
              cartdata !== null &&
              cartdata[0] &&
              cartdata[0].storename
              } Do you want to discard the selection and add items from ${props.storename
              }?`}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              paddingTop: "35px",
            }}
          >
            <button
              onClick={() => setOpen(false)}
              style={{
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: 700,
                border: "none",
                width: "40%",
                padding: "15px 40px",
                borderRadius: "30px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                color: "#000",
                background: "rgba(239, 241, 237, 1)",
              }}
            >
              {"No"}
            </button>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            <button
              onClick={() =>
                removeStore(
                  cartdata &&
                  cartdata !== null &&
                  cartdata[0] &&
                  cartdata[0].storeid
                )
              }
              style={{
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: 700,
                border: "none",
                width: "40%",
                padding: "15px 40px",
                borderRadius: "30px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                color: "#fff",
                background: "rgba(106, 169, 42, 1)",
              }}
            >
              {"Yes"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      {openAlert && (
        // <Snackbar
        //   open={openAlert}
        //   autoHideDuration={1000}
        //   onClose={handleClose}
        //   anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //   style={{position: "fixed", top: "15%", transform: "translatex(-50%)",zIndex:3,  }}
        // >
        //   <Alert
        //     onClose={handleClose}
        //     severity="error"
        //     variant="filled"
        //     anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //     autoHideDuration={3000}
        //     sx={{ width: "100%", zIndex: 3  }}
        //   >
        //     Cannot add more than one store at a time. Please remove any store
        //     from the cart to continue.
        //   </Alert>
        // </Snackbar>
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "13px",
              backgroundColor: "#ffffff",
              width: "450px",
              padding: "25px",
            },
          }}
          // fullScreen={fullScreen}
          maxWidth={"md"}
          open={openAlert}
          // onClose={handleStoreClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {`Replace Cart Item?`}
          </DialogTitle>
          <DialogContent
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
            }}
          >
            {cartdata.map((cartItem) => (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                Your cart contains items from {cartItem.storename} do you want to discard the selection and add items from {props?.storename}?


                <div
                  style={{
                    width: "80%",
                    marginTop: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "8px",
                    display: "flex",
                    gap: "30px"
                  }}
                >


                  <button style={{ padding: "10px 50px", background: "#e0dcdc", borderRadius: "10px", border: "transparent", fontWeight: "600", cursor: "pointer" }} onClick={handleStoreClose}>
                    {"No"}
                  </button>

                  <button
                    key={cartItem.storeid} // Adding a key prop for list rendering
                    style={{ padding: "10px 50px", background: "#85BE49", borderRadius: "10px", color: "white", border: "transparent", fontWeight: "600", cursor: "pointer" }}
                    onClick={() => removestorefromcart(cartItem.storeid)}
                  >
                    {"Yes"}
                  </button>



                </div>
              </div>))}
          </DialogContent>
        </Dialog>

      )}
    </React.Fragment>
  );
};
export default ExpandButton;
