import React from 'react';
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import ReduxProvider from "./redux";
import { ApolloProvider } from "react-apollo";
import client from "./apollo";
import PrivacyPolicy from '../Components/PrivacyPolicy';
import SigninPage from '../Routes/SigninPage';
// import CheckoutPage from '../Layouts/CheckoutPage';
import PaymentScreen from '../Components/PaymentScreen';
import PlaceOrder from '../Layouts/PlaceOrder';
import MainPage from '../Routes/MainPage';
import TermsAndConditions from '../Components/TermsAndConditions';
import AboutUsPage from '../Components/AboutUsPage';
import Contactus from '../Components/Contactus';
import ReferFriends from '../Routes/ReferFriends';
import Campaign from '../Routes/Campaign';
import PageNotFound from '../Routes/PageNotFound';
import DownloadApp from './DownloadApp';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import io from 'socket.io-client';
import SearchProductAndStore from "../Components/Search/Search";
import CancelationPolicyTerms from "../Components/CancellationPolicy/index"

const MainFile = (props) => {
    const navigate = useNavigate();
    let params = useParams();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [socket, setSocket] = React.useState(null);

    React.useEffect(() => {
      const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
      setSocket(newSocket);
      return () => newSocket.close();
    }, [setSocket]);
    return (
        <div id="root">
            <ReduxProvider>
                <ApolloProvider client={client}>
                {matches?
                    <DownloadApp 
                    params={params}
                    navigate={navigate}
                    {...props} />
                    :
                    null
                    }
                    <Routes>
                        <Route exact={true} path={'/'} element={<MainPage navigate={navigate}
                            params={params}
                            {...props} />} />
                        <Route exact={false} path={'/stores/*'} element={<SigninPage navigate={navigate}
                            params={params} 
                            socket={socket}
                            {...props} />} />
                        {/* <Route exact={true} path={'/checkout'} element={<CheckoutPage navigate={navigate}
                            params={params} {...props} />} /> */}
                             <Route exact={true} path={'/checkout'} element={<PaymentScreen navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/order-succesfull'} element={<PlaceOrder navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/termsandconditions'} element={<TermsAndConditions navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/privacypolicy'} element={<PrivacyPolicy navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/AboutUs'} element={<AboutUsPage navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/ContactUs'} element={<Contactus navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/invite'} element={<ReferFriends navigate={navigate}
                            params={params} {...props} />} />
                        <Route exact={true} path={'/campaign'} element={<Campaign navigate={navigate}
                            params={params} {...props} />} />
                             <Route path="*" element={<PageNotFound navigate={navigate}
                            params={params} {...props} />} />
                         <Route exact={true} path={'/Search'} element={<SearchProductAndStore navigate={navigate}
                            params={params} {...props} />} />
                             <Route exact={true} path={'/refundandcancelationpolicy'} element={<CancelationPolicyTerms navigate={navigate}
                            params={params} {...props} />} />
                    </Routes>
                </ApolloProvider>
            </ReduxProvider>

        </div>

    );
}
export default MainFile;