export function Default() {
    return {
        isLoggingIn: false,
        isLogInSuccess: false,
        isLogInFailed: false,
        isLoadingPlaces: false,
        isPlaceDescription: false,
        isPlaceDescriptionFailed: false,
        isVerifyOTP: false,
        isSigningUp: false,
        isSignUpSuccess: false,
        isSignUpFailed: false,
        isStoresLoading: false,
        isStoresSuccess: false,
        isStoresFailed: false,
        cartCount: 0,
        slots: [],
        SelectedDateSlot: [],
        CheckoutInstructions: [],
        selectedcouponslist: [],
        isAddaddressFailed: false,
        NotificationLength: 0,
        isSendOTPFailed: false,
        isCartLimit: false,
        availablecouponsLoading: false,
        availablecouponssuccess: false,
        availablecouponsfailed: false,
        totalCouponDiscount: 0,
        Complaints: [],
        SubComplaints: [],
        previousComplaintsLoading: false,
        previousComplaints: false,
        OrderComplaints: [],
        SelectedItemsList: [],
        submititems: false,
        SelectedItemsImage: null,
        submitimage:false,
        SelectedOption:{
            refund:false,
            replacement:false
        },
        Cart:null,
        Selected_Date:null,
        Selected_Time:null,
        isQuantityUpdating:false,
        isQuantityUpdated:false,
        isQuantityUpdtaedFailed:false,
        isDeliveryDateTimeSelected:false,
    };
}
