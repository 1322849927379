import React from "react";
import { useState } from "react";
import client from "../../../Configurations/apollo";
import { Pay_Now, Verify_Payment, create_order_2 } from "./newPaymentQueries";
import Axios from "axios";
import { useStyles } from "../../OrderCard/style";
import { useEffect } from "react";
import { db } from "../../db";
import PlaceOrder from "../../../Layouts/PlaceOrder/index";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Pay = ({ props, concated_Address }) => {
  const [dateTimeOpen, setDateTimeOpen] = useState(false);
  const [mobilenumber, setMobilenumber] = useState(false);
  const [address, setaddress] = useState(false);
  const { cart } = db;
  useEffect(() => {
    props.getUserDetails(localStorage.getItem("userid"));
  }, []);
  const [paid, setPaid] = React.useState(false);
  const classes = useStyles();

  console.log("this is berlin for delivery details",props.DeliveryInstructions)
  const handleClose = () => {
    setDateTimeOpen(false);
    setMobilenumber(false)
    setaddress(false)
  };
  const orderCreation = async (
    userid,
    address,
    deliveryType,
    deliverydate,
    deliverytime,
    specialinstruction,
    deliveryInstructions,
    mobileNumber,
    type
  ) => {
    
    if (deliverydate === undefined || deliverytime === null ) {
      
      setDateTimeOpen(true);
      return;
    }
    if (mobileNumber === null || mobileNumber === undefined) {
      
      setMobilenumber(true);
      return
    }
    if(address === undefined || address=== null || address === " "|| !address){
      setaddress(true)
      return
    }
    try {
      const res = await client
        .mutate({
          mutation: create_order_2,
          variables: {
            userid: userid,
            deliveryAddress: address,
            deliveryType: deliveryType,
            deliveryDate: deliverydate,
            deliveryTime: deliverytime,
            specialInstructions: specialinstruction,
            deliveryInstructions: deliveryInstructions,
            mobileNumber: props.Account.phoneNumber,
            type: type,
          },
        })
        .then((res) => {
          const orderId = res?.data?.createOrder2?.id;

          const API_URL = "https://www.nearshopz.com/";
          client
            .mutate({
              mutation: Pay_Now,
              variables: {
                orderid: orderId,
              },
            })
            .then((res) => {
              if (res.data && res.data.payNow && res.data.payNow !== null) {
                const options = {
                  key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
                  amount: 100,
                  currency: "INR",
                  name: "NearShopz",
                  description: "Grocery delivery from shops near you!",
                  order_id: res.data.payNow.id && res.data.payNow.id,
                  handler: async (response) => {
                    try {
                      const paymentId = response.razorpay_payment_id;
                      // alert(response.razorpay_payment_id);
                      // alert(response.razorpay_order_id);
                      // alert(response.razorpay_signature);
                      client
                        .mutate({
                          mutation: Verify_Payment,
                          variables: {
                            orderid: orderId,
                            razorpayOrderid: `${response.razorpay_order_id}`,
                            paymentid: `${response.razorpay_payment_id}`,
                            signature: `${response.razorpay_signature}`,
                          },
                        })
                        .then((res) => {
                          setPaid(true);
                          client.reFetchObservableQueries();
                        })
                        .catch((err) => {
                          alert(err);
                        });
                      // console.log("id", paymentId);
                      const url = `${API_URL}capture/${paymentId}`;
                      const captureResponse = await Axios.post(url, {});
                    } catch (err) {
                      console.log(err);
                    }
                  },
                  theme: {
                    color: "#89C74A",
                  },
                };
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
                db.cart.clear();
                props.navigate("/order-succesfull");
              } else if (res.errors) {
                alert(res.errors[0].message);
              }
            })
            .catch((err) => {
              alert(err);
            });
        });
    } catch (err) {
      
      console.error("Error creating order", err);
      alert(err);
    }
  };

  return (
    <div>
      {
        <button
          style={{
            width: "10.9375rem",
            height: "3.125rem",
            fontSize: "18px",
          }}
          className={paid === true ? classes.disabledpaynow : classes.paynow}
          disabled={paid === true ? true : false}
          onClick={() =>
            orderCreation(
              props?.Cart?.userid?.id,
              concated_Address,
              "delivery",
              props?.Selected_Details?.selected_Date,
              props?.Selected_Details?.selected_Time,
              props?.CookingInstructions,
              props.DeliveryInstructions,
              props.Account.phoneNumber,
              1
            )
          }
        >
          {paid === true ? (
            "Paid"
          ) : (
            <b>Pay ₹ {`${props?.Cart?.totalPayable?.toFixed(2)}`} </b>
          )}
        </button>
      }

      {paid === true ? (
        <>
          <PlaceOrder />
        </>
      ) : null}
      {dateTimeOpen && (
        <>
          {" "}
          <Snackbar
            open={dateTimeOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ top: "15%", transform: "translatex(-50%)" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
              sx={{ width: "100%" }}
            >
              Please Select Date and Time
            </Alert>
          </Snackbar>
        </>
      )}
      {mobilenumber && (
        <>
          <Snackbar
            open={mobilenumber}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ top: "15%", transform: "translatex(-50%)" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
              sx={{ width: "100%" }}
            >
              Please Add your Mobile number
            </Alert>
          </Snackbar>
        </>
      )}

{address && (
        <>
          <Snackbar
            open={address}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ top: "15%", transform: "translatex(-50%)" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
              sx={{ width: "100%" }}
            >
              Please Add your Address
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};
export default Pay;
