import { gql } from "@apollo/client";

//QUERIES

export const STORE_DETAILS = gql`
  query($storeid:ID!)
{
 store(storeid:$storeid){
  id
  storeCoordinates
  {
    coordinates
  }
  storeLocation
  storeName
  storeLogoUrl
  businessTypeId
  businessType
  storeStatus
  storeWorking
  storeManagerPhone
}
}`

export const GET_STORES = gql`
query ($lat:Float!,$lng:Float!)
{
    storelistdelivery(lat:$lat,lng:$lng)
    { 
        id
        storeName
        storeLogoUrl
        businessType
        storeLocation
        storeDescription
        businessTypeId
    }
}`

export const BUSINESS_TYPES = gql`
query{
    businessType{
        id
        name
        coverImage
        iconImage
    }
}`


//MY ACCOUNT PAGE 
export const GET_USER_DETAILS = gql`
  query ($userid: ID) {
    userdetails(userid: $userid) {
      id
      firstName
      lastName
      recentSearches
      email
      password
      phoneNumber
      verified
    }
  }
`;
export const DELETE_ACCOUNT = gql`
mutation($userid:ID,$reason:String){
  deleteAccount(userid:$userid,reason:$reason)
}`;
export const GET_ADDRESS = gql`
  query ($userid: ID) {
    userdetails(userid: $userid) {
      id
      address {
        id
        locationLat
        locationLng
        houseNumber
        locationDescription
        landmark
      }
    }
  }
`;
//MY ORDERS
export const ACTIVE_ORDERS = gql`
  query ($userid: ID) {
    orderHistoryActive(
      userid: $userid
    ) {
      orderNumber
      id
      totalPayable
      version
      total
      handlingCharge
      packingCharge
      GST
      deliveryTip
      orderNumber
      paymentStatus
      products {
        id
      }
      storeid {
        id
        storeName
        storeLogoUrl
      }
      lastStatus
      deliveryCharge
      totalPriceDelivery
      finalBillAmount
      totalPriceUser
      totalPrice
      discountPrice
    }
  }
`;
export const ACTIVE_ORDERS_LENGTH = gql`
  query ($userid: ID) {
    orderHistoryActive(
      userid: $userid
    ) {
      id
    }
  }
`;
export const FINISHED_ORDERS = gql`
  query ($userid: ID, $offset: Int, $first: Int) {
    orderHistoryFinished(
      userid: $userid
      pagination: { offset: $offset, first: $first }
    ) {
      items {
        id
        totalPayable
        version
        total
        handlingCharge
        packingCharge
        GST
        deliveryTip
        orderNumber
        paymentStatus
        ratings {
          rating
        }
        products {
          id
        }
        storeid {
          id
          storeName
          storeLogoUrl
        }
        lastStatus
        deliveryCharge
        finalBillAmount
        totalPriceDelivery
        totalPriceUser
        totalPrice
        finalBillAmount
        discountPrice
      }
    }
  }
`;
export const UPDATE_CART_QUANTITY = gql`
  mutation ($storeid: ID!, $products: ProductsInput!) {
    updateCartQuantity(storeid: $storeid, products: $products) {
      id
      userid {
        id
        primaryLatitude
        primaryLongitude
      }
      storeid {
        id
        storeName
        storeLogoUrl
      }
      products {
        productid {
          id
          productname
          price
          status
          image {
            primary
            secondary
          }
          promoprice
        }
        price
        quantity
      }
      totalPrice
      productCount
      totalPriceUser
    }
  }
`;
export const GET_CART = gql`
  query ($userid: ID) {
    cart(userid: $userid) {
      id
      storeid{
        id
        storeName
        storeManagerMobile
        availableDeliveryDays{
          date
          timeslots
        }
        businessType
        businessTypeId
        storeLogoUrl
        deliveryType
        openingTime
        closingTime
        holidays
        storeWorking
        storeStatus
      }
      userid{
        id
      }
      products{
        productid{
          productname
          promoprice
          uom
          image
          {
           primary
           secondary
         }
        }
        id
        productPrice
        quantity
        price
        shopAssistantQuantity
        status
      }
      availableAddress{
        _id
        houseNumber
        landmark
        locationDescription
        distance{
          distance{
            text
            value
          }
          duration{
            text
            value
          }
        }
        deliveryCharge
      }
      deliveryCharge
      addressid
      GST
      packingCharge
      handlingCharge
      deliveryTip
      totalPrice
      total
      totalPayable
      preparationTime
    }
  }
`;
// CHECKOUT
export const GET_CHECKOUTCART = gql`
  query($userid: ID) {
    cart(userid: $userid) {
      id
      products {
        productid {
          id
          productname
          price
          status
          image {
            primary
            secondary
          }
          promoprice
          quantity
          uom
        }
        price
        quantity
      }
      storeid
      {
        id
        storeName
        storeLogoUrl
        firstDeliveryDay
        businessTypeId
        secondDeliveryDay
        thirdDeliveryDay
      }
      totalPrice
      productCount
      totalPriceUser
    }
  }
`;

export const GET_DELIVERY_CHARGE = gql`
  query deliveryCharge($storeid: [ID]!, $lat: Float!, $lng: Float!) 
  {
      deliveryCharge(storeid:$storeid,lat:$lat,lng:$lng){
        deliveryCharge
        storeid
      }
    }
`;
export const GET_USER_PHONENUMBER = gql`
  query ($userid: ID) {
    userdetails(userid: $userid) {
      id
      phoneNumber
    }
  }
`;
// CATEGORY_LISTING
export const GET_CATEGORY_LIST = gql`
query salescategory($storeid: ID!, $id: ID!) {
  salescategory(storeid: $storeid, id: $id) {
    id
    name
    childCategoryPresent
    storeid
    parentid {
      id
      name
    }
    categoryProducts (pagination:{first:10,offset:0}){
      items {
        id
        productname
        price
        images
        image {
          primary
          secondary
        }
        promoprice
        uom
        quantity
        bxgy{
          active
          xvalue
          yvalue
        }
      }
    }
  }
}
`;

export const GET_CATEGORY1_LIST = gql`
  query salescategory($storeid: ID!, $id: ID!) {
    salescategory(storeid: $storeid, id: $id) {
      id
      name
      childCategoryPresent
      storeid
      parentid {
        id
        name
      }
      category1Products(pagination:{first:10,offset:0}) {
        items {
          id
          productname
          price
          images
          image {
            primary
            secondary
          }
          promoprice
          uom
        quantity
        bxgy{
          active
          xvalue
          yvalue
        }
        }
      }
    }
  }
`;

export const GET_CATEGORY2_LIST = gql`
  query salescategory($storeid: ID!, $id: ID!) {
    salescategory(storeid: $storeid, id: $id) {
      id
      name
      childCategoryPresent
      storeid
      parentid{
        name
      }
      category2Products(pagination:{first:10,offset:0}) {
        items {
          id
          productname
          price
          images
          image {
            primary
            secondary
          }
          promoprice
          uom
        quantity
        bxgy{
          active
          xvalue
          yvalue
        }
        }
      }
    }
  }
`;
export const GET_DEPARTMENT_PRODUCTS = gql`
    query($departmentid:ID!,$first:Int,$offset:Int)
    {
      salesdepartmentproductlist(departmentid:$departmentid,pagination:{first:$first,offset:$offset})
      {
        hasNext
          items
          {
            id
            productname
            price
            images
            image{
              primary
              secondary
            }
            quantity
            uom
            storeid
            promoprice
            bxgy{
              active
              xvalue
              yvalue
            }
            salesdepartment
            {
              id
             name
            }
            
          }
      }
    }`
export const GET_CATEGORY_PRODUCTS = gql`
      query($categoryid:ID!,$first:Int,$offset:Int)
      {
        salescategoryproductlist(categoryid:$categoryid,pagination:{first:$first,offset:$offset})
        {
            hasNext
            items
            {
              id
              productname
              price
              images
              image{
                primary
                secondary
              }
              quantity
            uom
              storeid{
                id
              }
              promoprice
              bxgy{
                active
                xvalue
                yvalue
              }
              salesdepartment
              {
                id
               name
              }
              salescategory{
                id
                name
              }
            }
        }
      }`
export const GET_CATEGORY1_PRODUCTS = gql`
      query($categoryid1:ID!,$first:Int,$offset:Int)
      {
        salescategory1productlist(categoryid1:$categoryid1,pagination:{first:$first,offset:$offset})
        {
          hasNext
          items
          {
            id
            productname
            price
            images
            quantity
            uom
            image{
              primary
              secondary
            }
            storeid
            promoprice
            bxgy{
              active
              xvalue
              yvalue
            }
            salescategory{
              id
              name
            }
            salescategory1
            {
              id
              name
            }
            salesdepartment
            {
              id
              name
            }
            
            }
        }
      }`
export const GET_CATEGORY2_PRODUCTS = gql`
      query($categoryid2:ID!,$first:Int,$offset:Int)
      {
        salescategory2productlist(categoryid2:$categoryid2,pagination:{first:$first,offset:$offset})
        {
          hasNext
          items
          {
            id
            productname
            price
            images
            image{
              primary
              secondary
            }
            storeid
            quantity
            uom
            promoprice
            bxgy{
              active
              xvalue
              yvalue
            }
            salescategory
            {
              id
              name
            }
            salescategory2
            {
              id
              name
            }
            salescategory1
            {
              id
              name
            }
            salesdepartment
            {
              id
              name
            }
            
            }
        }
      }
    `
export const GET_PRODUCT_DETAILS = gql`
query($id:ID!,$storeid:ID!,$userid:ID){
  salesproducts(id:$id){
     id
     productname
     desc
     mfgname
     brand
     price
     promoprice
     quantity
     image
     {
       primary
       secondary
     }
     uom
     storeid{
      id
     }
     bxgy{
      active
      xvalue
      yvalue
    }
     inCartStore(arguments:{storeid:$storeid,userid:$userid})
     inCartProductQuantity(arguments:{storeid:$storeid,userid:$userid})
     salesdepartment{
      id
      name
    }
    salescategory{
      id
      name
    }
    salescategory1{
      id
      name
    }
    salescategory2{
      id
      name
    }
   relatedProducts{
     items
     {
       id
       productname
       price
      promoprice
      quantity
      uom
       image
       {
         primary 
         secondary
       }
       bxgy{
        active
        xvalue
        yvalue
      }
       inCartStore(arguments:{storeid:$storeid,userid:$userid})
      inCartProductQuantity(arguments:{storeid:$storeid,userid:$userid})
     }
   }
   }
}`
//MUTATIONS

export const ADD_STORE_TO_FAVOURITES = gql`
mutation ($storeid:ID){
    addStoreToUserInfo(storeid:$storeid)
    }`


export const SUGGESTIONS = gql`
query($placeid:String!)
{
  googlemapplacedetails(placeid:$placeid)
  {
    lat
    lng
  }
}`

export const AUTOCOMPLETE = gql`
query($Input:String!)
{
  googlemapautocomplete(input:$Input)
  {
    description
    place_id
  }
}`

export const GET_SIGNUP = gql`
mutation($email:String!,
        $password:String!,
        $firstName:String!,
        $lastName:String!,
        $fcmToken:String)
        {
        createUser(email :$email,
                password : $password, 
                firstName : $firstName, 
                lastName:$lastName,
                fcmToken:$fcmToken) 
                {
                     userid
                     token
                     primaryLatitude
                     primaryLongitude
                     primaryLocation
                }
        }`

export const GET_MOBILESIGNUP = gql`
  mutation($phoneNumber:String!,
    $firstName:String!,
    $lastName:String!,
    $primaryLatitude:Float!
    $primaryLongitude:Float!,
    $primaryLocation:String!)
    {
    createUserMobile(phoneNumber:$phoneNumber,
                    firstName:$firstName, 
                    lastName:$lastName,
                    primaryLatitude:$primaryLatitude,
                    primaryLongitude:$primaryLongitude,
                    primaryLocation:$primaryLocation)
	                {
	                    id
	                    verified
	                    firstName
                        lastName
                        primaryLatitude
                        primaryLongitude
                        primaryLocation
	                }
  }`

export const GET_MOBILEOTP = gql`
  mutation($phoneNumber:String!,
    $otp:Int!,$fcmToken:String)
    {
    verifyOTP(phoneNumber:$phoneNumber,
            otp:$otp,fcmToken:$fcmToken)
	        {
	            userid
                token
                primaryLatitude
                primaryLongitude
                primaryLocation
	        }
  }`
export const GET_DEPARTMENT_LIST = gql`
  query salesdepartments($storeid: ID!) {
    salesdepartments(storeid: $storeid) {
      id
      name
      childCategoryPresent
      storeid
      departmentProducts(pagination:{first:10,offset:0}) {
        items {
          id
          productname
          price
          images
          image {
            primary
            secondary
          }
          promoprice
          quantity
          uom
          bxgy{
            active
            xvalue
            yvalue
          }
        }
      }
    }
  }
`;
export const GET_AVAILABLE_ADDRESS = gql`
  query {
    availableAddressUser {
      availableAddressList{
      id
      houseNumber
      locationDescription
      landmark
      locationLat
      locationLng
      }
      totalAddressCount
    }
  }
`;
//ADD TO CART
export const ADD_TO_CART = gql`
  mutation ($storeid: ID!, $userid: ID, $products: [ProductsInput]!) {
    createCart(storeid: $storeid, userid: $userid, products: $products) {
      id
      userid {
        id
        primaryLatitude
        primaryLongitude
      }
      storeid {
        id
        storeName
        storeLogoUrl
      }
      products {
        productid {
          id
          productname
          price
          image {
            primary
            secondary
          }
          promoprice
          status
        }
        price
        quantity
      }
      totalPrice
      productCount
      totalPriceUser
    }
  }
`;
export const UPDATE_CART = gql`
  mutation ($storeid: ID!, $userid: ID, $products: [ProductsInput]!) {
    updateCart(storeid: $storeid, userid: $userid, products: $products) {
      id
      userid {
        id
        primaryLatitude
        primaryLongitude
      }
      storeid {
        id
        storeName
        storeLogoUrl
        businessType
      }
      products {
        productid {
          id
          productname
          price
          status
          image {
            primary
            secondary
          }
          promoprice
        }
        price
        quantity
      }
      totalPrice
      productCount
      totalPriceUser
    }
  }
`;
export const REMOVE_PRODUCT = gql`
  mutation ($userid: ID, $productid: ID!, $storeid: ID!) {
    deleteoneproductcart(
      userid: $userid
      productid: $productid
      storeid: $storeid
    ) {
      id
      userid {
        id
        primaryLatitude
        primaryLongitude
      }
      storeid {
        id
        storeName
        storeLogoUrl
      }
      products {
        productid {
          id
          productname
          price
          status
          image {
            primary
            secondary
          }
          promoprice
        }
        price
        quantity
      }
      totalPrice
      productCount
      totalPriceUser
    }
  }
`;
export const GET_CART_COUNT = gql`
  query ($userid: ID) {
    cart(userid: $userid) {
      productCount
    }
  }
`;

export const UPDATE_CART_AT_CHECKOUT = gql`
  mutation updateCartAtCheckout {
    updateCartAtCheckout{
      id
    }
  }
`;

export const UPDATE_FULL_CART = gql`
mutation updateFullCart($userid:ID,$storeid:ID!,$products:[ProductsInput]!){
updateFullCart(userid:$userid,storeid:$storeid,products:$products){
  id
  storeid{
    id
    storeName
    storeManagerMobile
    availableDeliveryDays{
      date
      timeslots
    }
    businessType
    businessTypeId
    storeLogoUrl
    deliveryType
    openingTime
    closingTime
    holidays
    storeWorking
    storeStatus
  }
  userid{
    id
  }
  products{
    productid{
      id
      productname
      promoprice
      uom
      image
      {
       primary
       secondary
     }
    }
    id
    productPrice
    quantity
    price
    shopAssistantQuantity
    status
  }
  availableAddress{
    _id
    houseNumber
    landmark
    locationDescription
    distance{
      distance{
        text
        value
      }
      duration{
        text
        value
      }
    }
    deliveryCharge
  }
  deliveryCharge
  addressid
  GST
  packingCharge
  handlingCharge
  deliveryTip
  totalPrice
  total
  totalPayable
  preparationTime

}

} `;

export const updateCart2 = gql`
  mutation updateCart2($storeid: ID!, $userid: ID!, $addressId: String) {
    updateCart2(storeid: $storeid, userid: $userid, addressId: $addressId) {
      id
      storeid {
        id
        storeName
        storeManagerMobile
        availableDeliveryDays {
          date
          timeslots
        }
        businessType
        storeLogoUrl
        deliveryType
        openingTime
        closingTime
        holidays
        storeWorking
        storeStatus
      }
      userid {
        id
      }
      couponCode
      discountPrice
      products {
        id
        productid {
          id
          productname
          uom
          quantity
        }
        productPrice
        quantity
        price
        shopAssistantQuantity
        status
      }

      availableAddress {
        _id
        houseNumber
        landmark
        type
        locationDescription
        locationLat
        locationLng
        distance {
          distance {
            text
            value
          }
          duration {
            text
            value
          }
        }
        deliveryCharge
      }
      GST
      packingCharge
      addressid
      handlingCharge
      deliveryTip
      totalPrice
      total
      totalPayable
      deliveryCharge
      preparationTime
      couponId {
        id
        type
        name
        couponCode
        custUseCount
        totalCustUseCount
        discountPercent
        minPurAmt
        appliedCount
      }
      couponError
      couponIsApplied
      couponRequiredAmt
      discountPrice
      couponStatus
      productCount
      totalPriceUser
    }
  }
`;
export const quantity_changes = gql`

  mutation updateCart2($storeid: ID!, $userid: ID!,$product: ProductsInput) {
    updateCart2(storeid: $storeid, userid: $userid,product:$product ) {
      id
      storeid {
        id
        storeName
        storeManagerMobile
        availableDeliveryDays {
          date
          timeslots
        }
        businessType
        storeLogoUrl
        deliveryType
        businessTypeId
        openingTime
        closingTime
        holidays
        storeWorking
        storeStatus
      }
      userid {
        id
      }
      couponCode
      discountPrice
      products {
        id
        productid {
          id
          productname
          uom
          quantity
        }
        productPrice
        quantity
        price
        shopAssistantQuantity
        status
      }

      availableAddress {
        _id
        houseNumber
        landmark
        type
        locationDescription
        locationLat
        locationLng
        distance {
          distance {
            text
            value
          }
          duration {
            text
            value
          }
        }
        deliveryCharge
      }
      GST
      packingCharge
      addressid
      handlingCharge
      deliveryTip
      totalPrice
      total
      totalPayable
      deliveryCharge
      preparationTime
      couponId {
        id
        type
        name
        couponCode
        custUseCount
        totalCustUseCount
        discountPercent
        minPurAmt
        appliedCount
      }
      couponError
      couponIsApplied
      couponRequiredAmt
      discountPrice
      couponStatus
      productCount
      totalPriceUser
    }
  }
`;



export const DeliveryTip = gql`
  mutation updateCart2($storeid: ID!, $userid: ID!, $deliveryTip: Float) {
    updateCart2(storeid: $storeid, userid: $userid, deliveryTip: $deliveryTip) {
      storeid {
        id
        storeName
        availableDeliveryDays {
          date
          timeslots
        }
        storeManagerMobile
        storeLogoUrl
        deliveryType
        openingTime
        closingTime
        holidays
        storeWorking
        storeStatus
      }
      userid {
        id
      }
      couponCode
      discountPrice
      products {
        id
        productid {
          id
          productname
          uom
          quantity
        }
        productPrice
        quantity
        price
        shopAssistantQuantity
        status
      }

      availableAddress {
        _id
        houseNumber
        landmark
        type
        locationDescription
        locationLat
        locationLng
        distance {
          distance {
            text
            value
          }
          duration {
            text
            value
          }
        }
        deliveryCharge
      }

      GST
      packingCharge
      addressid
      handlingCharge
      deliveryTip
      totalPrice
      total
      totalPayable
      deliveryCharge
      preparationTime
      couponId {
        id
        type
        name
        couponCode
        custUseCount
        totalCustUseCount
        discountPercent
        minPurAmt
        appliedCount
      }
      couponError
      couponIsApplied
      couponRequiredAmt
      discountPrice
      couponStatus
      productCount
      totalPriceUser
    }
  }
`;

export const applyBestCoupon = gql`
mutation applyBestCoupon($storeid: ID!, $userid: ID!) {
  applyBestCoupon(storeid: $storeid, userid: $userid) {
    id
    storeid {
      id
      storeName
      availableDeliveryDays {
        date
        timeslots
      }
      storeManagerMobile
      storeLogoUrl
      deliveryType
      openingTime
      businessType
      businessTypeId
      closingTime
      holidays
      storeWorking
      storeStatus
    }
    userid {
      id
    }
    products {
      id
      productid {
        id
        productname
        uom
        quantity
      }
      productPrice
      quantity
      price
      shopAssistantQuantity
      status
    }
    availableAddress {
      _id
      houseNumber
      landmark
      type
      locationDescription
      locationLat
      locationLng
      distance {
        distance {
          text
          value
        }
        duration {
          text
          value
        }
      }
      deliveryCharge
    }
    deliveryCharge
    addressid
    GST
    packingCharge
    handlingCharge
    deliveryTip
    totalPrice
    total
    totalPayable
    preparationTime
    couponCode
    couponId {
      id
      type
      name
      couponCode
      custUseCount
      totalCustUseCount
      discountPercent
      minPurAmt
      appliedCount
    }
    couponError
    couponIsApplied
    couponRequiredAmt
    discountPrice
    couponStatus
    productCount
    totalPriceUser
  }
}
`
export const CHECK_MIN_AMOUNT = gql`
query checkMinamount{
  checkMinAmount
  
}`;

export const CLEAR_CART = gql`
mutation clearcart{
  deleteAllStoresFromCart
}`;

export const DELETE_STORE = gql`
mutation  deletestorefromcart($storeid:ID!){
  deleteStoreFromCart(storeid:$storeid)
}`;

export const SEND_OTP = gql`
  mutation OTP($phoneNumber: String!, $userid: ID!) {
    sendOTP(phoneNumber: $phoneNumber, userid: $userid)
  }
`;
export const VERIFY_OTP = gql`
  mutation($phoneNumber: String!, $otp: Int!,$userid:ID!) {
    verifyOTP(phoneNumber: $phoneNumber, otp: $otp,userid:$userid) {
      userid
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation createorder($userid:ID!,
           $storeid:[ID]!,
           $specialInstructions:[String],
           $deliveryType:String!,
           $deliveryAddress:String!,
           $deliveryDate:[String]!,
           $deliveryTime:[String]!,
           $deliveryLat:Float,
           $deliveryLng:Float,
           $mobileNumber:String!,
           $deliveryCharge:[Float]!,
           $totalPriceDelivery :[Float]!,
           $type:Int!)
           {
              createOrder(userid:$userid,
                          storeid:$storeid,
                          specialInstructions:$specialInstructions,
                          deliveryType:$deliveryType,
                          deliveryAddress:$deliveryAddress,
                          deliveryDate:$deliveryDate,
                          deliveryTime:$deliveryTime,
                          deliveryLat:$deliveryLat,
                          deliveryLng:$deliveryLng,
                          mobileNumber:$mobileNumber,
                          deliveryCharge:$deliveryCharge,
                          totalPriceDelivery :$totalPriceDelivery,
                          type:$type )
                          {
                            id  
                            orderNumber
                            storeid{
                              id
                              openingTime
                              closingTime
                              holidays
                            }                     
                          }
              }`

export const SCHEDULE_ALERT = gql`
 mutation($storeid:[ID],$workingDays:[workingDaysInput],$orderid:[ID],$orderNumber :[Int]){
  scheduleAlert(storeid:$storeid,workingDays:$workingDays,orderid:$orderid,orderNumber :$orderNumber)
}
 `;
export const LIVE_ORDERS_LENGTH = gql`
 query ($userid: ID) {
   orderHistoryActive(
     userid: $userid
   ) {
     orderNumber
   }
 }
`;
export const LIVE_ORDERS = gql`
  query ($userid: ID) {
    orderHistoryActive(
      userid: $userid
    ) {
      id
      totalPayable
      version
      total
      handlingCharge
      packingCharge
      GST
      deliveryTip
      orderNumber
      notifications{
        id
        message
        created
      }
      lastStatus
    }
  }
`;
export const ADD_RATING = gql`
  mutation ($orderid: ID!, $ratings: ratingsInput!) {
    addRating(orderid: $orderid, ratings: $ratings) {
      id
      ratings {
        rating
      }
    }
  }
`;
export const UPDATE_PRIMARY_LOCATION = gql`
mutation($userid:ID!,$primaryLatitude:Float!,$primaryLongitude:Float!,$primaryLocation:String){
	updateUser(userid:$userid,primaryLatitude:$primaryLatitude,primaryLongitude:$primaryLongitude,primaryLocation:$primaryLocation)
	{
	  id
	}
  }
`
export const SUGGESTIONS_CORDINATES = gql`
  query($placeid: String!) {
    googlemapplacedetails(placeid: $placeid) {
      lat
      lng
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation ($userid: ID!, $address: userAddressInput!) {
    updateUser(userid: $userid, address: $address) {
      id
      address {
        id
        locationLat
        locationLng
        houseNumber
        landmark
        locationDescription
      }
    }
  }
`;
export const EDIT_ADDRESS = gql`
  mutation (
    $userid: ID!
    $address: userAddressInput!
    $newAddress:userAddressInput!
  ) {
    updateUser(userid: $userid, address: $address,newAddress:$newAddress) {
      id
      address {
        id
        locationLat
        locationLng
        houseNumber
        landmark
        locationDescription
      }
    }
  }
`;
export const DELETE_ADDRESS = gql`
  mutation ($userid: ID!, $address: userAddressInput!) {
    deleteOneAddressOfUser(userid: $userid, address: $address) {
      id
      address {
        id
        locationLat
        locationLng
        houseNumber
        landmark
        locationDescription
      }
    }
  }
`;
export const EDIT_ACCOUNT = gql`
  mutation (
    $userid: ID!
    $firstName: String!
    $lastName: String!
  ) {
    updateUser(
      userid: $userid
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      firstName
      lastName
      email
      password
      phoneNumber
      verified
    }
  }
`;

export const SEARCH = gql`
  query (
    $searchKey: String
    $storeid: ID!
    $from: Int
    $size: Int
  ) {
    searchproducts(
      searchKey: $searchKey
      storeid: $storeid
      from: $from
      size: $size
    ) {
      _id
      _source {
        productname
        price
        image {
          primary
          secondary
        }
      }
    }
  }
`;

export const REFER_FRIEND = gql`
query ($customerName:String,$email:String){
  referFriends(customerName:$customerName,email:$email)
  }
`;

export const UPDATE_PASSWORD = gql`
mutation ($userid: ID!, $password: String!, $newPassword: String!) {
  updateUser(
    userid: $userid
    password: $password
    newPassword: $newPassword
  ) {
    id
    firstName
    lastName
    email
    password
    phoneNumber
    verified
  }
}
`;

export const ADD_PASSWORD = gql`
mutation ($userid: ID!, $newPassword: String!) {
  updateUser(
    userid: $userid
    newPassword: $newPassword
  ) {
    id
    firstName
    lastName
    email
    password
    phoneNumber
    verified
  }
}
`;

export const EDIT_EMAIL = gql`
  mutation ($userid: ID!, $newEmail: String!) {
    updateUser(userid: $userid, newEmail: $newEmail) {
      id
    }
  }
`;
export const ADD_EMAIL = gql`
  mutation ($userid: ID!, $newEmail: String!) {
    updateUser(userid: $userid, newEmail: $newEmail) {
      id
      firstName
      lastName
      email
      password
      phoneNumber
      verified
    }
  }
`;

export const ORDER = gql`
  query orderdetails($orderid: ID!) {
    orderdetails(orderid: $orderid) {
      orderNumber
      id
      totalPayable
      version
      total
      handlingCharge
      packingCharge
      GST
      deliveryTip
      paymentActive
      customerName
      stats {
        status
        created
      }
      lastStatus
      storeid {
        id
        storeName
        storeLogoUrl
      }
      products {
        id
        shopAssistantQuantity
        price
        productPrice
        productid {
          image {
            primary
            secondary
          }
          productname
          promoprice
          price
        }
        quantity
      }
      notifications{
        id
        message
        created
      }
      paymentStatus
      finalBill
      finalBillAmount
      deliveryDate
      deliveryTime
      deliveryAddress
      deliveryCharge
      totalPriceDelivery
      totalPriceUser
      totalPrice
      discountPrice
      estimatedDeliveryTime
      couponID{
        type
        discountPercent
        disAmt      
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation ($orderid: ID!, $stats: StatusInput!) {
    cancelOrder(orderid: $orderid, stats: $stats) {
      id
      orderNumber
      storeid
      {
        id
      }
      deliveryDate
      deliveryTime
      shopAssistantId
      {
        id
      }
    }
  }
`;
export const SCHEDULE_ALERT_ON_STATUS = gql`
mutation($orderNumber:Int,$status:String,$storeid:ID,$orderid:ID,$deliveryDate:String,
$deliveryTime:String,$shopassistantId:ID){
  scheduleAlertOnStatus(orderNumber:$orderNumber,status:$status,storeid:$storeid,orderid:$orderid,deliveryDate:$deliveryDate,
    deliveryTime:$deliveryTime,shopassistantId:$shopassistantId)
}`;

export const PLACEORDER_ADDRESS = gql`
  query($userid: ID!, $lat: Float!, $lng: Float!) {
    placeOrderNewAddressCheck(userid: $userid, lat: $lat, lng: $lng)
  }
`;

export const RESTRICT_ORDER = gql`
query{
  restrictOrder
}
`;

export const GET_PLACE_BY_LATLNG = gql`
query($latlng:String){
  googlemapreversegeocoding(latlng:$latlng)
  {
    formatted_address
    place_id
  }
}
`;

// COUPONS

export const AVAILABLE_COUPONS = gql`
query($storeIds:[ID],$userId:ID!){
  availableCouponsList(storeIds:$storeIds,userId:$userId){
    id 
    type
    name
    couponCode
    custUseCount
    totalCustUseCount
    discountPercent
    minPurAmt
    disAmt
    startDate
    endDate
    getItemNo
    buyItemNo
    appliedCount
    description
    status
    storeid
  }
}`;

export const APPLY_COUPON = gql`
query($couponId:ID,$storeId:ID,$totalPrice:Float){
  applyCoupon(couponId:$couponId,storeId:$storeId,totalPrice:$totalPrice){
    storeid {
      id
      storeName
      availableDeliveryDays {
        date
        timeslots
      }
      storeLogoUrl
      deliveryType
      openingTime
      closingTime
      holidays
      storeWorking
      storeStatus
    }
    userid {
      id
    }
    couponCode
    discountPrice
    products {
      id
      productid {
        id
        productname
        uom
        quantity
      }
      productPrice
      quantity
      price
      shopAssistantQuantity
      status
    }

    availableAddress {
      _id
      houseNumber
      landmark
      type
      locationDescription
      locationLat
      locationLng
      distance {
        distance {
          text
          value
        }
        duration {
          text
          value
        }
      }
      deliveryCharge
    }

    GST
    packingCharge
    addressid
    handlingCharge
    deliveryTip
    totalPrice
    total
    totalPayable
    deliveryCharge
    preparationTime
    couponId {
      id 
    type
    name
    couponCode
    custUseCount
    totalCustUseCount
    discountPercent
    minPurAmt
    disAmt
    startDate
    endDate
    getItemNo
    buyItemNo
    appliedCount
    description
    status
    storeid
      
    }
    couponError
    couponIsApplied
    couponRequiredAmt
    discountPrice
    couponStatus
    productCount
    totalPriceUser
  }
}`;

export const REMOVE_COUPON = gql`
mutation($storeId:ID){
  removeCoupon(storeId:$storeId)
}
`;

export const REMOVE_APPLIED_COUPON = gql`
mutation removeCoupon($storeId:ID,$userid:ID){
  removeCoupon(storeId: $storeId, userid: $userid) {
  id
  storeid {
    id
    storeName
    availableDeliveryDays {
      date
      timeslots
    }
    storeLogoUrl
    deliveryType
    openingTime
    closingTime
    holidays
    storeWorking
    storeStatus
    minAmount
    storeLocation
  }
  userid {
    id
  }
  products {
    id
    productid {
      id
      productname
      uom
      quantity
    }
    productPrice
    quantity
    price
    shopAssistantQuantity
    status
  }
  availableAddress {
    _id
    houseNumber
    landmark
    type
     locationDescription
      locationLat
      locationLng
    distance {
      distance {
        text
        value
      }
      duration {
        text
        value
      }
    }
    deliveryCharge
  }
  deliveryCharge
  addressid
  GST
  packingCharge
  handlingCharge
  deliveryTip
  totalPrice
  total
  totalPayable
  preparationTime
  couponCode
      couponId
      {
          id
          type
          name
          couponCode
          custUseCount
          totalCustUseCount
          discountPercent
          minPurAmt
          appliedCount
      }
      couponError
      couponIsApplied
      couponRequiredAmt
      discountPrice
      couponStatus
      addressid
      productCount
      totalPriceUser

  }
} 
`

export const COMPLAINTS_LIST = gql`
  query($parentid: Int) {
    complaintsBot(parentid:$parentid)
  {
    id
    message
    parentid
    itemsList
    imageUpload
    quantity
    refund
    replacement
    
  }
  }
`;

export const PREVIOUS_COMPLAINTS = gql`
query($orderid:ID!){
  orderComplaints(orderid:$orderid)
  {
    id
    orderid{
      orderNumber
    }
    userMessage
    itemList
    image
    quantity
    refund
    refundStatus
    refundAmount
    replacement
    replacementStatus
    complaintStatus
  }
}
`;

export const ORDER_ITEMS_LIST = gql`
  query orderdetails($orderid: ID!) {
    orderdetails(orderid: $orderid) {
      id
      totalPayable
      version
      total
      handlingCharge
      packingCharge
      GST
      deliveryTip
      products {
        productid {
          id
          image {
            primary
            secondary
          }
          productname
        }
      }
    }
  }
`;

export const UPLOAD_IMAGE = gql`
mutation($file:Upload!,$type:Int,$orderid:String){
  signS3(file:$file,type:$type,orderid:$orderid)
    {
      ETag
      Bucket
      Location
      Key
    }
  }`;

export const REGISTER_COMPLAINT = gql`
  mutation (
    $orderid: ID!
    $userid: ID!
    $storeid: ID!
    $userMessage: [String]!
    $image: String
    $itemList: [String]
    $refund: Boolean
    $refundAmount: Float
    $refundStatus: String
    $replacement: Boolean
    $replacementStatus: String
  ) {
    createOrderComplaint(
      orderid: $orderid
      userid: $userid
      storeid: $storeid
      userMessage: $userMessage
      image: $image
      itemList: $itemList
      refund: $refund
      refundAmount: $refundAmount
      refundStatus: $refundStatus
      replacement: $replacement
      replacementStatus: $replacementStatus
    ) {
      id
    }
  }
`;

export const GET_POPULAR_PRODUCT = gql`
  query {
    findPopularProducts {
      id
      productName
      image
      displayRank
      version
    }
  }
`;

export const SEARCH_PRODUCTS_GLOBALLY = gql`
  query ($storeIds: [ID], $searchKey: String) {
    searchProductsGlobally(
      pagination: { offset: 0, first: 10 }
      storeIds: $storeIds
      searchKey: $searchKey
    ) {
      _id
      image
    }
  }
`;
export const GET_PRODUCT_SEARCH = gql`
  query ($pagination: PaginationArg, $storeIds: [ID], $searchKey: String) {
    getSearchResults(
      pagination: $pagination
      storeIds: $storeIds
      searchKey: $searchKey
    ) {
      count
      hasNext
      items {
        _id {
          id
          storeName
          storeWorking
          storeStatus
          storeLogoUrl
          minAmount
        }
        products {
          id
          productname
          price
          promoprice
          quantity
          uom
          image {
            primary
            secondary
          }
          bxgy{
            active
            xvalue
            yvalue
          }
        }
      }
    }
  }
`;
export const Update_Recent_Search = gql`
mutation($keyword:String){
  updateRecentSearchesOfUser(keyword:$keyword){
    recentSearches
  }
}
`;