import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import { ReactComponent as TimerBlackIcon } from "../../Assets/Icons/TimerBlack.svg";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    color: "#000000", // Ensure the icon color is black
    transition: "transform 0.4s",
    transform: "rotate(0deg)", // Default to pointing right
  },
  "&.Mui-focused .MuiSelect-icon": {
    transform: "rotate(90deg)", // Rotate down when open
  },
  "&.MuiSelect-iconOpen": {
    transform: "rotate(90deg)", // Rotate down when open
  },
  "&.MuiSelect-iconClosed": {
    transform: "rotate(0deg)", // Rotate right when closed
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove border
  },
  "&:focus .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove focus border
  },
  backgroundColor: "#F9F9F9",
  borderRadius: "17px",
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: "17px",
  boxShadow: "none",

  "& .css-1srlou9-MuiButtonBase-root-MuiMenuItem-root": {
    minHeight: "40px",
  },
  backgroundColor: "transparent", // Remove predefined background color
  "&.Mui-selected": {
    backgroundColor: "#2D93FB !important",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#2D93FB !important",
    },
  },
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  paddingTop: "1rem",
  paddingBottom: "1.5rem",
}));

const Calendar = (props) => {
  const [selectedDateTime, setSelectedDateTime] = React.useState({
    date: null,
    time: null,
  });

  const handleDateTimeChange = (date, time) => {
    setSelectedDateTime({ date, time });
    props?.setdateAndTimeForDelivery(date, time);
  };

  return (
    <>
      {props?.Cart?.storeid?.availableDeliveryDays?.map((deliveryDay) => {
        const isSelectedDate = selectedDateTime.date === deliveryDay.date;
        const isSelectedTime = isSelectedDate && selectedDateTime.time;
        const label = deliveryDay.date.replace(/\s\d{4}$/, "");

        return (
          <CustomFormControl fullWidth key={deliveryDay.date}>
            <CustomSelect
              className={isSelectedTime ? "Mui-selected" : ""}
              IconComponent={
                isSelectedTime
                  ? KeyboardArrowRightTwoToneIcon
                  : KeyboardArrowRightTwoToneIcon
              }
              value={isSelectedDate ? selectedDateTime.time : ""}
              onChange={(e) =>
                handleDateTimeChange(deliveryDay.date, e.target.value)
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return label;
                }
                return `${deliveryDay.date.replace(
                  /\s\d{4}$/,
                  ""
                )} ${selected}`;
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                  },
                },
              }}
              onOpen={() => {
                document
                  .querySelector(".MuiSelect-icon")
                  .classList.add("MuiSelect-iconOpen");
                document
                  .querySelector(".MuiSelect-icon")
                  .classList.remove("MuiSelect-iconClosed");
              }}
              onClose={() => {
                document
                  .querySelector(".MuiSelect-icon")
                  .classList.remove("MuiSelect-iconOpen");
                document
                  .querySelector(".MuiSelect-icon")
                  .classList.add("MuiSelect-iconClosed");
              }}
            >
              {deliveryDay?.timeslots?.map((time) => (
                <CustomMenuItem
                  key={time}
                  value={time}
                  data-date={deliveryDay.date}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <WatchLaterIcon
                      style={{
                        marginRight: "5px",
                      }}
                    />
                    {time}
                  </div>
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </CustomFormControl>
        );
      })}
    </>
  );
};

export default Calendar;
